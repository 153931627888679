
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  

  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://3d2f0188674f41fb8de8550ccb4b08e5@sentry.wixpress.com/2306',
      id: '3d2f0188674f41fb8de8550ccb4b08e5',
      projectName: 'payment-methods-banner-ooi',
      teamName: 'payments',
      errorMonitor: true,
    };

  var experimentsConfig = {"scopes":["payments-checkout"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/7a79e30a3f637800/packages/payment-methods-banner-ooi/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var defaultTranslations = {"settings.title":"Installment Payment Options","settings.tabs.design":"Design","settings.design.fontSettings":"Text font & color","settings.design.textAlignmentTitle":"Alignment","settings.design.logoThemeTitle":"Logo appearance","settings.design.logoTheme.dark":"Dark","settings.design.logoTheme.light":"Light","demoView.title":"or {installmentCount} interest-free payments of {currency}{amount} with"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/7a79e30a3f637800/packages/payment-methods-banner-ooi/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "payment-methods-banner-ooi",
    biConfig: null,
    appName: "payment-methods-banner-ooi",
    appDefinitionId: "df892fe9-626f-44c9-a328-e29f93880b38",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"scopes":["payments-checkout"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/7a79e30a3f637800/packages/payment-methods-banner-ooi/src/components/PaymentMethodsBannerWidget/controller.ts",
          appName: "payment-methods-banner-ooi",
          appDefinitionId: "df892fe9-626f-44c9-a328-e29f93880b38",
          projectName: "payment-methods-banner-ooi",
          componentName: "PaymentMethodsBannerWidget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "f6aa0c93-74ae-415c-a5e3-1bfc7c747bf4" }],
    false);

    export const createControllers = _createControllers
